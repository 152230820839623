(function($){

  "use strict";  

  jQuery(document).ready(function($){

    $('p').filter(function(){return $(this).text().trim().length===0}).remove();

    $('.slider-home').slick({
      dots: true,
      draggable: false,
      arrows: false,
      customPaging: function (slider, i) {
        return ' <i class="dots" aria-hidden="true"></i> ' ;
      }
    });
  });

})(jQuery);

function parallax() {
  let el = document.querySelectorAll('.bg-img');
  if ( el ) {
    el.forEach( function(e) {
      var yPos = window.pageYOffset / e.dataset.speed;
      yPos = -yPos;
      var coords = '0%' + yPos + 'px';      
      e.style.backgroundPosition = coords;      
    });
  }
};

window.addEventListener("scroll", function(){
  parallax();
});